import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-0430/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-0430 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-0430/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-0430/' crumbLabel="IN-0430" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-0430-standard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0430-standard",
        "aria-label": "in 0430 standard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0430 Standard`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3001 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3005 Indoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-3011/"
        }}>{`IN-3010/1 Indoor Camera`}</a></li>
      <li parentName="ul">{`IN-2901 Outdoor Camera`}</li>
      <li parentName="ul">{`IN-2904 Outdoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-2905_V2/"
        }}>{`IN-2905 V2 Outdoor Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/"
        }}>{`IN-6011/2 HD Indoor Camera`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c206584a31ec1e0bae532235b4b14c5f/1bba8/Lense_4.3mm_-_Angle_of_View.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC7klEQVQ4y2WUW2/bRhCF+eeLtkCBJEWAuA9tXSBu4Tiw0jSwrRspSqYsy44l8SreKS4vktOi/QdfsSvbQdOHwQzOzpw5M7ukNpiPGN6M6V8byg9vTPaYyeB2TF/v0vu9Q/ePdwymOoMbk+F8n6PfjLm0LEbGiMB1eXNygqZHU4bRFP0LG4ZT9GTG8M6ge3pM76yDHljo0RV6uK8xoxm3iyWL+S1t1ZBmOZruWypx+IU9Yvp6iu5Z6P5UxfuzKcO1hWFf0+25OB892qbBdlw0VRA82EPB/8glrvKszzlrCzsOuZ2HRG5Is93i+T7a0DbpB5f0fWkTjOiKUTTDCK8wopkqHviXTyQyV8ZOFlDVDZUoEWlG3W5xPQ9tfmex8j5yGy+5TpaczQZ8mPc4X+i8n3cx42sm6Y1qIKeYxXdEm5S6aihEhUhzyqL8TJjES2pR02YFwcrh7PyCD2dnPHvxnK+++Zpvn33H67e/cWH0ibKUqmqo6xbH9ZnOrsnDCCEqqqbF9wO0KFpSVhXNdodljimjmCxJMSeXdDrvOHh1wJvjE05PO8RxqsYUda0Ircsp2TpE1A110+K6Hloc25SiZrvbMRpPGJtjjPMLjg5/4dXBD7w++pVev48xGhHFyZ6wqimrmiLNEPlGxVK1HwRoSeIiqn1SXmwwJxPennY4ePmSox9/4vvnLzg+OeGi21PnpaiU34iKMkkRpVDYk8I0DRB1+wRud/fcf/qTv/7+h/v7T+w2gk2yH1UqyfJCxZtSqNuV+5O1EvPkDrMsVIQyIYwS8qJUcbHZe6lE5IXabZEV5JuSj4slru2wiRO1P0nYbne4ni8JI6q6JViH6IapwEcF5UN3qUwpiROClY3rB+RJSpkXilCqXtkui8USrSwL2u29IrAdTymU3eT41UP3R1JFLEmSjNj1KEuhnss6jDDHFpY1RbOdFYvlCttx1Le4XNncLZasbEeplpf1aOXDe0vTjMOfD/+zT/ljkDX/AoGQDD/yzbwFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c206584a31ec1e0bae532235b4b14c5f/e4706/Lense_4.3mm_-_Angle_of_View.avif 230w", "/en/static/c206584a31ec1e0bae532235b4b14c5f/d1af7/Lense_4.3mm_-_Angle_of_View.avif 460w", "/en/static/c206584a31ec1e0bae532235b4b14c5f/5e9eb/Lense_4.3mm_-_Angle_of_View.avif 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c206584a31ec1e0bae532235b4b14c5f/a0b58/Lense_4.3mm_-_Angle_of_View.webp 230w", "/en/static/c206584a31ec1e0bae532235b4b14c5f/bc10c/Lense_4.3mm_-_Angle_of_View.webp 460w", "/en/static/c206584a31ec1e0bae532235b4b14c5f/7eb86/Lense_4.3mm_-_Angle_of_View.webp 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c206584a31ec1e0bae532235b4b14c5f/81c8e/Lense_4.3mm_-_Angle_of_View.png 230w", "/en/static/c206584a31ec1e0bae532235b4b14c5f/08a84/Lense_4.3mm_-_Angle_of_View.png 460w", "/en/static/c206584a31ec1e0bae532235b4b14c5f/1bba8/Lense_4.3mm_-_Angle_of_View.png 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c206584a31ec1e0bae532235b4b14c5f/1bba8/Lense_4.3mm_-_Angle_of_View.png",
              "alt": "IN-0430 Standard",
              "title": "IN-0430 Standard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The angle of view of the 4.3mm lense. Field Angle (D x H x V): 61.1° x 49.0° x 37.8°.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/c08c5/4.3mm_IRcut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHPWVxkaBZ//8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIRABMh/9oACAEBAAEFAlPiq0DQc7kAkTf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgEFAQAAAAAAAAAAAAAAABEBAxAhMkGh/9oACAEBAAY/AkjuRTTN/BObf//EAB0QAAMAAgIDAAAAAAAAAAAAAAABESFRMUFhcbH/2gAIAQEAAT8hiyRdciGCCM035NkehGonY6Z+H//aAAwDAQACAAMAAAAQdM//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCD/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAECAQE/EETIS//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVFhkcH/2gAIAQEAAT8QoT0R+FdxyqHZR13HI0Y0w8TDgXpT+wLicUFsEjT7LP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/e4706/4.3mm_IRcut.avif 230w", "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/d1af7/4.3mm_IRcut.avif 460w", "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/e6133/4.3mm_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/a0b58/4.3mm_IRcut.webp 230w", "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/bc10c/4.3mm_IRcut.webp 460w", "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/0ba47/4.3mm_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/e83b4/4.3mm_IRcut.jpg 230w", "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/e41a8/4.3mm_IRcut.jpg 460w", "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/c08c5/4.3mm_IRcut.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9bc93c64a7703adc7fcc5bad6cb1523/c08c5/4.3mm_IRcut.jpg",
              "alt": "IN-0430 Standard",
              "title": "IN-0430 Standard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 4.3mm lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <h2 {...{
      "id": "in-0430-standard-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0430-standard-lense-chart",
        "aria-label": "in 0430 standard lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0430 Standard Lense Chart`}</h2>
    <p>{`Lense chart for the 4.3mm for the 1/4" CMOS Sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "639px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/738b8/Lense_Chart_4_6mm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB00lEQVQoz3VSy27TQBT1V4HUPSg7yjcgdogFKzapuiiikQCVL2BB9yUsusxfREpi47qx40c945mJx54ZzwvFExuoxF1Z1nnce+Z41lpjtFJKa22tZYxF0f1ms/F9P0kS3/fX6/VqtYrj2AHGadvWk7KLorswDLtO9D+NEFwIrobpuk4IoVUvzduu6xyZUuqlWfrq7Zvp+9e3t3OldNu2Dw/F9+tvjLUOpLU2/SAMy7JACGGMj+S8KN6dTyenky+fPxFClsul6PivYD2SjbGMtWVZNE1jzEFLKWWtrevaY4xxzkhN1WExo7UyxllpFwfCFYRQSsk4LUDSS5g/5MHBuEjcku5CAEpCMOO0hDtYFZyz/X5PCDmSOedjgO68/kMhXCFUYQIBTEGVC8H/BvzXmXEGYAFAXuGc7KEQYsCbx2RKqWM6YYhAsrsryhjh0j1eTzgGrpQajzqQXXTW2qal0f0m3oU1RUpJhzD/uj0uSR+9zIptEC4RBq4M7oQjf5jADxaLxcXFh6urr5ez2Xz+0zPabJMgzbd6WMm95LiRa66U8ubmx/Ts7MnTkxenL589n3y8nHljq40xUsrRp6qqLMvSNAUAOEWtD/1rmqauadM0AIDf7HXYdKsJkRMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e07170f0b6a9c6af10abed0ddd2628d7/e4706/Lense_Chart_4_6mm.avif 230w", "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/d1af7/Lense_Chart_4_6mm.avif 460w", "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/a8125/Lense_Chart_4_6mm.avif 639w"],
              "sizes": "(max-width: 639px) 100vw, 639px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e07170f0b6a9c6af10abed0ddd2628d7/a0b58/Lense_Chart_4_6mm.webp 230w", "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/bc10c/Lense_Chart_4_6mm.webp 460w", "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/d95e5/Lense_Chart_4_6mm.webp 639w"],
              "sizes": "(max-width: 639px) 100vw, 639px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e07170f0b6a9c6af10abed0ddd2628d7/81c8e/Lense_Chart_4_6mm.png 230w", "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/08a84/Lense_Chart_4_6mm.png 460w", "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/738b8/Lense_Chart_4_6mm.png 639w"],
              "sizes": "(max-width: 639px) 100vw, 639px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e07170f0b6a9c6af10abed0ddd2628d7/738b8/Lense_Chart_4_6mm.png",
              "alt": "IN-0430 Standard",
              "title": "IN-0430 Standard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.3 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.60 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<   -8.8%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61.1° x 49.0° x 37.8°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.0 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      